<template>
  <div>
    <profile-header text="My Wallet" />

    <v-container class="d-flex flex-column align-center">
      <div>
        <div>
          <span class="balance">${{balance}}</span> 
        </div>
      </div>
      <div class="d-flex flex-row justify-space-around">
        <v-btn color="mx-3 black lighten-2 white--text">Add Credits</v-btn>
        <v-btn color="mx-3 black lighten-2 white--text">Purchase Pod</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader.vue'
export default {
  components: { ProfileHeader },
  data() {
    return { balance: 0 };
  },
  mounted() {
    this.balance = Math.floor(Math.random() * 2000);
  }
}
</script>

<style lang="scss" scoped>
.balance {
  text-align: center;

  font-size: 5rem;
  font-weight: 200;

  padding: 1rem;
}
</style>